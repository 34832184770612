import React, { useEffect, useState } from 'react';
import dateFormat from 'dateformat';

import { Dialog, Input } from 'svs-utils/react';

import './editCreditCard.scss';

function EditCreditCardDialog(props) {
    var { close, editParams, submit } = props;

    var [startDate, setStartDate] = useState(new Date());
    var [endDate, setEndDate] = useState(new Date());
    var [venmo, setVenmo] = useState({});

    useEffect(() => {
        var newVenmo = editParams.venmo || {};
        newVenmo = { ...newVenmo, from: newVenmo.from?.map((item) => [item.person, item.amount]) || [] };

        setStartDate(editParams.startDate ? editParams.startDate : startDate);
        setEndDate(editParams.endDate ? editParams.endDate : endDate);
        setVenmo(newVenmo);

    }, [editParams.cardId]); // eslint-disable-line react-hooks/exhaustive-deps

    var handleSubmitClick = () => {
        startDate = dateFormat(startDate, 'yyyy-mm-dd');
        endDate = dateFormat(endDate, 'yyyy-mm-dd');
        venmo = { ...venmo, from: venmo.from.map((item) => ({ person: item[0], amount: item[1] })) };

        submit({ ...editParams, cardId: editParams.cardId, startDate, endDate, venmo });
    }

    if (venmo.amount == null) {
        venmo.amount = 0;
    }
    if (venmo.from == null) {
        venmo.from = [];
    }

    return (
        <Dialog
            className='dialogEditCreditCard'
            close={close}
            header={'Edit Credit Card'}
            height={350}
            width={350}
        >
            <div className='editCreditCardContainer'>
                <Input label='Start Date' value={startDate} type='date' onChange={setStartDate} />
                <Input label='End Date' value={endDate} type='date' onChange={setEndDate} />
                <Input
                    label='Venmo Amount'
                    value={venmo.amount}
                    type='number'
                    onChange={(value) => setVenmo({ ...venmo, amount: parseFloat(value) || '' })}
                />
                {venmo.amount > 0 && (
                    <Input
                        label='Venmo Paid From'
                        value={venmo.from}
                        type='doublePill'
                        onChange={(value) => setVenmo({ ...venmo, from: value })}
                    />
                )}
                <Input label='Submit' type='button' className='submitButton' onClick={handleSubmitClick} />
            </div>
        </Dialog>
    );
}

export default EditCreditCardDialog;
