import { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ajax, makeQueryString } from '../utils/web/webUtils.js';
import { AppLayoutContext } from './appLayout/appLayout.js';
import { LoginContext } from './loginProvider/loginProvider.js';
export function useCloseOnEscape(close) {
  let savedCallback = useRef(); // Remember the latest close function.

  useEffect(() => {
    savedCallback.current = close;
  }, [close]); // set the event listeners

  useEffect(() => {
    var closeOnEscape = event => {
      if (event.key === 'Escape') {
        savedCallback.current();
      }
    };

    window.addEventListener('keyup', closeOnEscape);
    return () => window.removeEventListener('keyup', closeOnEscape);
  }, []);
}
export function useAppLayout() {
  var appLayout = useContext(AppLayoutContext);
  return appLayout;
}
export function useLogin() {
  var login = useContext(LoginContext);
  return login;
}
export function useEnterKey(func) {
  var onEnter = event => {
    if (event.key === 'Enter') {
      func(event);
    }
  };

  return onEnter;
}
export function useNavigateCmdClick(path) {
  var navigate = useNavigate();
  return function (event) {
    if (event.metaKey || event.ctrlKey) {
      window.open(path, '_blank');
    } else {
      navigate(path);
    }
  };
}
; // from https://overreacted.io/making-setinterval-declarative-with-react-hooks/

export function useInterval(callback, delay) {
  let savedCallback = useRef(); // Remember the latest callback.

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]); // Set up the interval.

  useEffect(() => {
    if (typeof delay === 'number' && delay >= 0) {
      let id = setInterval(() => savedCallback.current(), delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
export function useWindowListener(type, callback) {
  let savedCallback = useRef(); // Remember the latest close function.

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]); // set the event listeners

  useEffect(() => {
    var listener = event => savedCallback.current(event);

    window.addEventListener(type, listener);
    return () => window.removeEventListener(type, listener);
  }, [type]);
}
export function useAjaxOnce(params, callback) {
  let savedCallback = useRef();
  let ajaxProm = useRef(null);
  let savedResults = useRef(null);
  var ajaxEndPoint = params.endPoint + makeQueryString(params.data || {}); // Remember the latest callback.

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]); // Set up the interval.

  useEffect(() => {
    if (!ajaxProm.current && !savedResults.current) {
      ajaxProm.current = ajax(params);
      ajaxProm.current.then(results => {
        ajaxProm.current = null;
        savedResults.current = results;
        savedCallback.current(results);
      });
    }
  }, [ajaxEndPoint]);
}
var globalAjaxResults = {};
export function useAjaxGlobalCache(params, callback) {
  let savedCallback = useRef();
  let ajaxProm = useRef(null);
  let savedResults = useRef(null);
  var ajaxEndPoint = params.endPoint + makeQueryString(params.data || {}); // Remember the latest callback.

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]); // Set up the interval.

  useEffect(() => {
    if (globalAjaxResults[ajaxEndPoint] && !savedResults.current) {
      ajaxProm.current = null;
      savedResults.current = globalAjaxResults[ajaxEndPoint];
      savedCallback.current(globalAjaxResults[ajaxEndPoint]);
    } else if (!ajaxProm.current && !savedResults.current) {
      ajaxProm.current = ajax(params);
      ajaxProm.current.then(results => {
        ajaxProm.current = null;
        globalAjaxResults[ajaxEndPoint] = results;
        savedResults.current = results;
        savedCallback.current(results);
      });
    }
  }, [ajaxEndPoint]);
}