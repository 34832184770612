import React, { useEffect, useState } from 'react';
import dateFormat from 'dateformat';

import { Dialog, Input } from 'svs-utils/react';

import './addNewPurchase.scss';

function AddNewDialog(props) {
    var { close, creditCards, categories1, categories2, rewardsPercents, editParams = {}, submit } = props;

    var [date, setDate] = useState(new Date());
    var [item, setItem] = useState('');
    var [card, setCard] = useState('');
    var [amount, setAmount] = useState('');
    var [category1, setCategory1] = useState('');
    var [category2, setCategory2] = useState('');
    var [venmo, setVenmo] = useState({});
    var [rewardsPercent, setRewardsPercent] = useState(0);
    var [recurring, setRecurring] = useState('N');
    var [recurringDayOfMonth, setRecurringDayOfMonth] = useState('');
    var [recurringStartMonth, setRecurringStartMonth] = useState(dateFormat('yyyy-mm'));
    var [recurringEndMonth, setRecurringEndMonth] = useState('');

    useEffect(() => {
        var newVenmo = editParams.venmo || {};
        newVenmo = { ...newVenmo, from: newVenmo.from?.map((item) => [item.person, item.amount]) || [] };

        setDate(editParams.transactionDate ? editParams.transactionDate : date);
        setItem(editParams.item || item);
        setCard(editParams.card || card || creditCards[0].value);
        setAmount(editParams.amount || amount);
        setCategory1(editParams.category1 || category1 || categories1[0].value);
        setCategory2(editParams.category2 || category2 || categories2[0].value);
        setVenmo(newVenmo);
        setRewardsPercent(parseFloat(editParams.rewardsPercent || rewardsPercent || rewardsPercents[0].value));
        setRecurring(editParams.recurring || recurring);
        setRecurringDayOfMonth(editParams.recurringDayOfMonth || recurringDayOfMonth);
        setRecurringStartMonth(editParams.recurringStartMonth || recurringStartMonth);
        setRecurringEndMonth(editParams.recurringEndMonth || recurringEndMonth);

    }, [editParams.id]); // eslint-disable-line react-hooks/exhaustive-deps

    var handleSubmitClick = (addAnother = false) => {
        date = dateFormat(date, 'yyyy-mm-dd');
        venmo = { ...venmo, from: venmo.from.map((item) => ({ person: item[0], amount: item[1] })) };

        submit({ addAnother, id: editParams.id, card, date, item, amount, category1, category2, venmo, rewardsPercent, recurring, recurringDayOfMonth, recurringStartMonth, recurringEndMonth });
    };

    if (venmo.amount == null) {
        venmo.amount = 0;
    }
    if (venmo.from == null) {
        venmo.from = [];
    }

    categories1.sort((a, b) => a.label.localeCompare(b.label));
    categories2.sort((a, b) => a.label.localeCompare(b.label));

    rewardsPercents.sort((a, b) => a.value - b.value);

    return (
        <Dialog
            className='dialogAddNew'
            close={close}
            header={(editParams?.id ? 'Edit Purchase' : 'Add New Purchase')}
            height={500}
            width={700}
        >
            <div className='newPurchaseContainer'>
                <div>
                    <Input label='Transaction Date' value={date} type='date' onChange={setDate} />
                    <Input label='Item' value={item} onChange={setItem} />
                    <Input label='Credit Card' value={card} type='select' selectItems={creditCards} onChange={setCard} />
                    <Input label='Amount' value={amount} type='number' onChange={setAmount} />
                    <Input label='Category 1' value={category1} type='select' selectItems={categories1} onChange={setCategory1} />
                    <Input label='Category 2' value={category2} type='select' selectItems={categories2} onChange={setCategory2} />
                </div>
                <div>
                    <Input label='Rewards Percent' value={parseFloat(rewardsPercent)} type='select' selectItems={rewardsPercents} onChange={setRewardsPercent} />
                    <Input
                        label='Venmo Amount'
                        value={venmo.amount}
                        type='number'
                        onChange={(value) => setVenmo({ ...venmo, amount: parseFloat(value) || '' })}
                    />
                    {venmo.amount > 0 && (
                        <Input
                            label='Venmo Paid From'
                            value={venmo.from}
                            type='doublePill'
                            onChange={(value) => setVenmo({ ...venmo, from: value })}
                        />
                    )}
                    <Input
                        label='Recurring Purchase'
                        value={recurring}
                        type='select'
                        selectItems={[{ value: 'Y', label: 'Yes'}, { value: 'N', label: 'No' }]}
                        onChange={setRecurring}
                    />
                    {recurring === 'Y' && (
                        <React.Fragment>
                            <Input label='Recurring Day of Month' value={recurringDayOfMonth} onChange={setRecurringDayOfMonth} />
                            <div className='recurringDatesContainer'>
                                <Input label='Recurring Start Month' value={recurringStartMonth} onChange={setRecurringStartMonth} placeholder='yyyy-mm' />
                                <Input label='End Month' value={recurringEndMonth} onChange={setRecurringEndMonth} placeholder='yyyy-mm' />
                            </div>
                        </React.Fragment>
                    )}
                </div>
                <Input type='button' label='Submit and Add Another' onClick={() => handleSubmitClick(true)} />
                <Input type='button' label='Submit' onClick={() => handleSubmitClick()} />
            </div>
        </Dialog>
    );
}

export default AddNewDialog;
