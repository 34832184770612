import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import { AppLayout } from 'svs-utils/react';

import { Home } from './components/index.js';

function AppRouter() {
    return (
        <Routes>
            <Route path='/' element={<AppLayout />}>
                {/* <Route path='p/:month' element={<Home />} /> */}
                <Route path=':homePath' element={<Home />}>
                    <Route path=':homePath2' element={<Home />}></Route>
                </Route>
                <Route path='404' element={<div>404</div>} />
                <Route index element={<Navigate to='/p' />} />
            </Route>
        </Routes>
    );
}

export default AppRouter;
