import { useEffect } from 'react';

import { ajax } from 'svs-utils/web';
import { createAlert, getGlobalStateUtils } from 'svs-utils/react';

var initialGlobalState = {
    spending: {
        months: {},
        monthTotals: [],
        siteInfo: null,
    },
};

export var { GlobalStateProvider, useStateSlice } = getGlobalStateUtils(initialGlobalState);

export function useFetchSiteInfo() {
    var [spendingSlice, setSpendingSlice] = useStateSlice('spending');
    var { siteInfo } = spendingSlice;

    var fetchSiteInfo = async () => {
        if (!siteInfo) {
            var endPoint = '/getSiteInfo';
            var results = await ajax({ endPoint });

            if (results.result) {
                var sections = ['creditCards', 'category1', 'category2', 'rewardsPercents'];
                siteInfo = {};
                for (var section of sections) {
                    siteInfo[section] = results[section].reduce((acc, item) => ({ ...acc, [item.value]: item }), {});
                }
                setSpendingSlice({ siteInfo });
            } else {
                createAlert(results.desc);
            }
        }
    };

    useEffect(() => {
        fetchSiteInfo()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
