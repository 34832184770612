import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
// import dateFormat from 'dateformat';

// import { ajax } from 'svs-utils/web';
import { MainMenu } from 'svs-utils/react';

import SpendingTables from './spendingTables.js';
import MonthTotals from './monthTotals.js';

import './home.scss';

let menuItems = {
    p: { title: 'Spending', shouldShow: () => true, content: () => <SpendingTables /> },
    monthtotals: { title: 'Month Totals', shouldShow: () => true, content: () => <MonthTotals /> },
};

function Home(props) {
    let navigate = useNavigate();
    let urlParams = useParams();

    useEffect(() => {
        let path = urlParams.homePath?.toLowerCase();
        if (!menuItems[path] || (!menuItems[path].shouldShow?.() && !menuItems[path].onlyDirect)) {
            navigate('/404');
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    let homePath = urlParams.homePath?.toLowerCase();

    return (
        <div className='home'>
            <MainMenu menuItems={menuItems} title={menuItems[homePath]?.title} type='lightSolid' />
            <div className={classNames('homeContentContainer')}>
                <div className='homeContent'>
                    {menuItems[homePath]?.content()}
                </div>
            </div>
        </div>
    );
}

export default Home;
