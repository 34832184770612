import React, { Component } from 'react';
// import dateFormat from 'dateformat';
import classNames from 'classnames';

import { Table2 } from 'svs-utils/react';
import './purchasesTable.scss';

class PurchasesTable extends Component {

    constructor(props) {
        super(props);
        var { editPurchase, deletePurchase } = this.props;

        this.state = {
            venmoDivVisible: false,
            venmoDivStyle: {},
            venmoDivContent: '',
        };

        this.headers = [
            { key: 'transactionDate', label: 'Transaction Date', dataType: 'date', width: 130 },
            { key: 'item', label: 'Item', dataType: 'string', width: 300 },
            { key: 'cardName', label: 'Credit Card', dataType: 'string', width: 175 },
            {
                key: 'totalAmount',
                label: 'Amount',
                dataType: 'number',
                format: 'money',
                className: ({ row }) => classNames({ hasVenmo: !!row.venmo?.amount }),
                cellActions: {
                    onMouseEnter: (object) => this.onPurchaseAmountEnter(object),
                    onMouseLeave: (object) => this.onPurchaseAmountLeave(object),
                },
            },
            { key: 'rewardsPercent', label: 'Rewards', dataType: 'string', className: 'centered' },
            { key: 'category1Name', label: 'Category 1', dataType: 'string', width: 120 },
            { key: 'category2Name', label: 'Category 2', dataType: 'string', width: 120 },
        ];
        this.clickGroups = [
            [
                { name: 'Edit', onClick: (object) => editPurchase(object) },
                { name: 'Delete', onClick: (object) => deletePurchase(object) },
            ]
        ];
    }

    onPurchaseAmountEnter({ event, row: purchase }) {
        var { venmo = {} } = purchase;
        if (venmo.amount) {
            var box = event.target.getBoundingClientRect();
            this.setState({
                venmoDivVisible: true,
                venmoDivStyle: { // +1, -1 are to account for cell border
                    top: box.top + 1,
                    left: box.left + box.width - 1,
                },
                venmoDivContent: (
                    <React.Fragment>
                        Original: ${purchase.amount + '\n\n'}
                        {`${venmo.from.map((f) => `$${f.amount} from ${f.person}`).join('\n')}`}
                        {venmo.from?.length > 1 && <div style={{ textAlign: 'left' }}>Total: ${venmo.amount}</div>}
                    </React.Fragment>
                )
            });
        }
    }

    onPurchaseAmountLeave() {
        var { venmoDivVisible } = this.state;
        if (venmoDivVisible) {
            this.setState({ venmoDivVisible: false, venmoDivStyle: {} });
        }
    }

    render() {
        var { purchases } = this.props;
        var { venmoDivVisible, venmoDivStyle, venmoDivContent } = this.state;

        return (
            <React.Fragment>
                <Table2 headers={this.headers} rows={purchases} clickGroups={this.clickGroups} maxHeight={'calc(100vh - 170px)'} alternateColors={true} compressed={true} />
                {venmoDivVisible && (
                    <div className='venmoDetailsDiv' style={venmoDivStyle}>{venmoDivContent}</div>
                )}
            </React.Fragment>
        );
    }
}

export default PurchasesTable;
