import React, { useEffect } from 'react';
import classNames from 'classnames';
// import dateFormat from 'dateformat';

import { ajax, parseDate } from 'svs-utils/web';
import { Loading, Table2, useAppLayout } from 'svs-utils/react';

import { useFetchSiteInfo, useStateSlice } from '../utils/reactUtils.js';

import './monthTotals.scss';

function MonthTotals(props) {
    var [spendingSlice, setSpendingSlice] = useStateSlice('spending');
    var appLayout = useAppLayout();

    var { monthTotals, siteInfo } = spendingSlice;
    var { creditCards } = siteInfo || {};

    useFetchSiteInfo();

    useEffect(() => {
        if (siteInfo) {
            fetchMonthInfo();
        }
    }, [siteInfo]); // eslint-disable-line react-hooks/exhaustive-deps

    var fetchMonthInfo = async () => {
        if (!monthTotals?.length) {
            var results = await ajax({ endPoint: '/getMonthTotals', data: {} });

            if (results.result) {
                let newMonthTotals = [];
                for (let [month, totals] of Object.entries(results.monthTotals)) {
                    let monthDate = parseDate(month);
                    newMonthTotals.push({ monthDate, ...totals });
                }

                setSpendingSlice({ monthTotals: newMonthTotals });
            }
        }
    };

    if (!siteInfo || !monthTotals?.length) {
        return <Loading />;
    }

    var smallLayout = appLayout.isMobile || ['XS', 'S'].includes(appLayout.layoutSize);

    let headers = [
        { key: 'monthDate', label: 'Month', dataType: 'date', width: 130, dateFormat: 'mmm yyyy' },
        ...Object.values(creditCards).map((cc) => ({
            key: `cc${cc.value}`, label: cc.label, dataType: 'number', format: 'money', width: 100
        })),
    ];

    let months = monthTotals.map((m) => ({
        monthDate: m.monthDate,
        ...Object.values(creditCards).reduce((acc, cc) => ({
            ...acc,
            [`cc${cc.value}`]: (m[cc.value]?.sum || 0) - (m[cc.value]?.cardInfo.venmo?.amount || 0)
        }), {}),
    }));

    return (
        <div className='monthTotalsContainer'>
            {/* <div className='myTableSectionHeader'>Month Totals</div> */}
            <div className={classNames('myTableContainer homeBoxShadow monthTotals', { smallLayout })}>
                <Table2 headers={headers} rows={months} maxHeight={'calc(100vh - 130px)'} alternateColors={true} compressed={true} />
            </div>
        </div>
    );
}

export default MonthTotals;
