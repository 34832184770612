import React, { useEffect, useState } from 'react';
// import dateFormat from 'dateformat';
import classNames from 'classnames';

import { round } from 'svs-utils/web';
import { Table2, useAppLayout } from 'svs-utils/react';

import './creditCardsTable.scss';

function CreditCardsTable(props) {
    var { editCreditCard, monthInfo } = props;

    var [venmoDivVisible, setVenmoDivVisible] = useState(false);
    var [venmoDivStyle, setVenmoDivStyle] = useState({});
    var [venmoDivContent, setVenmoDivContent] = useState('');

    var onPurchaseAmountEnter = ({ event, row: purchase }) => {
        var { venmo = {} } = purchase;
        if (venmo.amount) {
            var box = event.target.getBoundingClientRect();

            setVenmoDivVisible(true);
            setVenmoDivStyle({ top: box.top + 1, left: box.left + box.width - 1 }); // +1, -1 are to account for cell border
            setVenmoDivContent(
                <React.Fragment>
                    Original: ${purchase.total + '\n\n'}
                    {`${venmo.from.map((f) => `$${f.amount} from ${f.person}`).join('\n')}`}
                    {venmo.from?.length > 1 && <div style={{ textAlign: 'left' }}>Total: ${venmo.amount}</div>}
                </React.Fragment>
            );
        }
    };

    var onPurchaseAmountLeave = () => {
        setVenmoDivVisible(false)
        setVenmoDivStyle({});
    };

    var baseHeaders = [
        { key: 'cardName', label: 'Card Name', dataType: 'string', width: 170 },
        {
            key: 'adjustedTotal',
            label: 'Total',
            dataType: 'number',
            width: 90,
            format: 'money',
            className: ({ row }) => classNames({ hasVenmo: !!row.venmo?.amount }),
            cellActions: { onMouseEnter: onPurchaseAmountEnter, onMouseLeave: onPurchaseAmountLeave },
        },
        { key: 'purchaseCount', label: 'Count', dataType: 'number', width: 80, className: 'centered' },
        { key: 'adjustedUpToToday', label: 'Up To Today', dataType: 'number', width: 100, format: 'money' },
        { key: 'progress', label: 'Progress', dataType: 'string', width: 80, className: 'centered' },
        { key: 'projected', label: 'Projected', dataType: 'number', width: 90, format: 'money' },
    ];
    var movableHeaders = [
        { key: 'startDate', label: 'Statement Begin', dataType: 'date', width: 125, className: 'right' },
        { key: 'endDate', label: 'Statement End', dataType: 'date', width: 125, className: 'right' },
    ];

    var { layoutSize } = useAppLayout();
    var initialHeaders = [];
    if (['L', 'XL'].includes(layoutSize)) {
        initialHeaders = [...baseHeaders];
        initialHeaders.splice(1, 0, ...movableHeaders);
    } else {
        initialHeaders = [...baseHeaders, ...movableHeaders];
    }
    var [headers, setHeaders] = useState(initialHeaders);

    var clickGroups = [
        [
            { name: 'Edit', onClick: (object) => editCreditCard(object) },
        ]
    ];

    useEffect(() => {
        var newHeaders = [];
        if (['L', 'XL'].includes(layoutSize)) {
            newHeaders = [...baseHeaders];
            newHeaders.splice(1, 0, ...movableHeaders);
        } else {
            newHeaders = [...baseHeaders, ...movableHeaders];
        }
        setHeaders(newHeaders);
    }, [layoutSize]); // eslint-disable-line react-hooks/exhaustive-deps

    // Start Render Stuff

    // var cardRows = Object.values(monthInfo.cardInfo);
    // console.log(cardRows);
    var purchases = monthInfo.purchases;

    var now = new Date();
    var totalSum = 0;
    var totalSumUpToToday = 0;
    var cardSums = Object.values(monthInfo.cardInfo).reduce((acc, card) => ({ ...acc, [card.cardId]: { ...card, total: 0, purchaseCount: 0, upToToday: 0 } }), {});
    for (var purchase of purchases) {
        var card = cardSums[purchase.card];
        card.total = round(card.total + purchase.totalAmount, 2);
        card.purchaseCount++;
        if (purchase.transactionDate < now) {
            card.upToToday = round(card.upToToday + purchase.totalAmount, 2);
            totalSumUpToToday = round(totalSumUpToToday + purchase.totalAmount, 2);
        }

        totalSum = round(totalSum + purchase.totalAmount, 2);
    }

    var totalProjected = 0;
    var cardRows = Object.values(cardSums).map((card) => {
        var numberOfDays = round((card.endDate - card.startDate) / (1000 * 60 * 60 * 24));
        var today = (card.endDate < new Date()) ? new Date(card.endDate.getTime()) : new Date();
        // add an hour in case we are over a daylight savings spring forward // should have no effect everywhere else because of the Math.floor
        today.setHours(today.getHours() + 1);

        var currentDay = Math.floor((today - card.startDate) / (1000 * 60 * 60 * 24));
        var progress = Math.max(currentDay / numberOfDays, 0);

        var venmoAmount = card.venmo?.amount ? card.venmo.amount : 0;
        var adjustedTotal = card.total - venmoAmount;
        var adjustedUpToToday = card.upToToday - venmoAmount;
        totalSum = round(totalSum - venmoAmount, 2);
        totalSumUpToToday = round(totalSumUpToToday - venmoAmount, 2);

        var projected = progress ? round((adjustedUpToToday / progress) + (adjustedTotal - adjustedUpToToday), 2) : 0;
        totalProjected += projected;

        card.adjustedTotal = adjustedTotal;
        card.adjustedUpToToday = adjustedUpToToday;
        card.progress = round(progress * 100) + '%';
        card.projected = projected;

        return card;
    });

    var footerRows = [
        {
            adjustedTotal: totalSum,
            purchaseCount: purchases.length,
            adjustedUpToToday: totalSumUpToToday,
            projected: totalProjected
        },
        {
            adjustedUpToToday: 'Projected',
            progress: 'Remaining',
            projected: totalProjected - totalSum
        },
    ];

    // var totalsHeaders = [
    //     { key: 'totalSum', label: 'Total', dataType: 'number', format: 'money' },
    //     { key: 'totalCount', label: 'Count', dataType: 'number', className: 'centered' },
    //     { key: 'averageAmount', label: 'Average Charge', dataType: 'number', width: 120, format: 'money' },
    //     { key: 'totalSumUpToToday', label: 'Up To Today', dataType: 'number', format: 'money' },
    //     { key: 'totalProjected', label: 'Projected', dataType: 'number', format: 'money' },
    //     { key: 'projectedRemaining', label: 'Projected Remaining', dataType: 'number', width: 150, format: 'money' },
    // ];
    // var totalsRows = [
    //     {
    //         totalSum,
    //         totalCount: purchases.length,
    //         totalSumUpToToday,
    //         totalProjected,
    //         projectedRemaining: totalProjected - totalSum,
    //         averageAmount: round(totalSum / purchases.length, 2)
    //     },
    // ];

    return (
        <React.Fragment>
            <Table2 className='creditCardTotalsTable' headers={headers} rows={cardRows} footerRows={footerRows} clickGroups={clickGroups} maxHeight={400} customColors={true} compressed={true} showRowCount={false} />
            {/* <Table2 className='creditCardSumsTable' headers={totalsHeaders} rows={totalsRows} customColors={true} compressed={true} showRowCount={false} /> */}
            {venmoDivVisible && (
                <div className='venmoDetailsDiv' style={venmoDivStyle}>{venmoDivContent}</div>
            )}
        </React.Fragment>
    );
}

export default CreditCardsTable;
