import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import dateFormat from 'dateformat';

import { ajax, parseDate, round, sleep } from 'svs-utils/web';
import { createAlert, createNotify, confirmProm, Loading, Table, useAppLayout } from 'svs-utils/react';

import { useFetchSiteInfo, useStateSlice } from '../utils/reactUtils.js';
import { AddNewPurchaseDialog, EditCreditCardDialog } from './dialog/dialogs.js';
import PurchasesTable from './purchasesTable.js';
import CreditCardsTable from './creditCardsTable.js';

import './spendingTables.scss';

var ajaxCalls = {};

function SpendingTables(props) {
    var [addNewDialogOpen, setAddNewDialogOpen] = useState(false);
    var [editCreditCardDialogOpen, setEditCreditCardDialogOpen] = useState(false);
    var [editCreditCardParams, setEditCreditCardParams] = useState({});
    var [editPurchaseParams, setEditPurchaseParams] = useState({});

    var [spendingSlice, setSpendingSlice] = useStateSlice('spending');
    var appLayout = useAppLayout();
    var navigate = useNavigate()
    var urlParams = useParams();

    useFetchSiteInfo();

    var month = urlParams.homePath2;

    useEffect(() => {
        if (spendingSlice.siteInfo && month) {
            fetchMonthInfo();
        }
    }, [spendingSlice.siteInfo, month]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!month) {
            navigate(`/p/${dateFormat(new Date(), 'yyyy-mm')}`);
            return;
        }

        var match = month.match(/^(\d{4})-(\d{2})$/);
        if (!match) {
            navigate('/404');
            return;
        } else if (match[1] < 2020 || match[1] > 2024) {
            navigate('/404');
            return;
        } else if (match[2] < 1 || match[2] > 12) {
            navigate('/404');
            return;
        }

        fetchMonthInfo();
    });

    var fetchMonthInfo = async (month = null, fetchSurroundingMonths = true) => {
        while (!spendingSlice.siteInfo) {
            await sleep(10);
        }

        var { months, siteInfo: { creditCards } } = spendingSlice;
        month = month || urlParams.homePath2;

        var monthDate = parseDate(month);
        var endDate = getEndDateAllowed();
        if (monthDate > endDate) {
            return;
        } else if (monthDate < parseDate('2020-06-01')) {
            return;
        }

        var endPoint = '/getMonthInfo';
        var ajaxEndPoint = `${endPoint}-${month}`;
        ajaxCalls[ajaxEndPoint] = ajaxCalls[ajaxEndPoint] || {};
        while (ajaxCalls[ajaxEndPoint].running) {
            await sleep(10);
        }

        if (!months[month]) {
            ajaxCalls[ajaxEndPoint].running = true;
            var results = await ajax({ endPoint, data: { month }, allowConcurrent: true });
            ajaxCalls[ajaxEndPoint].running = false;

            if (results.result) {
                months[month] = months[month] || { ...results.monthInfo, purchases: [] }
                months[month].lastFetched = new Date();
                for (var [id, card] of Object.entries(months[month].cardInfo)) {
                    months[month].cardInfo[id] = {
                        ...months[month].cardInfo[id],
                        startDate: parseDate(card.startDate),
                        endDate: parseDate(card.endDate),
                        cardId: parseInt(id),
                        rowId: parseInt(id),
                        cardName: creditCards[id].label,
                        get noContextObject() { return this; },
                    };
                }
                for (var purchase of results.purchases) {
                    months[month].purchases.push(makePurchase(purchase));
                }
                months[month].purchases.sort((a, b) => a.transactionDate - b.transactionDate);
                setSpendingSlice({ months });
            }
        }

        if (fetchSurroundingMonths) {
            monthDate = parseDate(month);
            for (var i = 0; i < 3; i++) {
                monthDate.setMonth(monthDate.getMonth() - 1);
                fetchMonthInfo(dateFormat(monthDate, 'yyyy-mm'), false);
            }
            monthDate = parseDate(month);
            for (i = 0; i < 3; i++) {
                monthDate.setMonth(monthDate.getMonth() + 1);
                fetchMonthInfo(dateFormat(monthDate, 'yyyy-mm'), false);
            }
        }
    };

    var makePurchase = (purchase) => {
        var { siteInfo: { creditCards, category1, category2 } } = spendingSlice;

        purchase = {
            ...purchase,
            transactionDate: parseDate(purchase.transactionDate),
            cardName: creditCards[purchase.card].label,
            rewardsPercent: purchase.rewardsPercent ? `${purchase.rewardsPercent}%` : null,
            category1Name: category1[purchase.category1].label,
            category2Name: category2[purchase.category2].label,
            rowId: purchase.id,
            get noContextObject() { return this; },
            get totalAmount() {
                var venmoAmount = this.venmo?.amount || 0;
                return this.amount - venmoAmount;
            },
        };

        return purchase;
    };

    var createNewEntry = async (params) => {
        var { homePath2: month } = urlParams;
        var { months } = spendingSlice;

        var requiredParams = ['date', 'item', 'card', 'amount', 'category1', 'category2'];
        for (var requiredParam of requiredParams) {
            if (!params[requiredParam]) {
                return createAlert(`Missing params - ${requiredParam}`);
            }
        }
        if (params.venmo.amount && params.venmo.amount < 0) {
            createAlert('Venmo cannot be negative');
            return;
        }
        if (params.recurring === 'Y') {
            if (!params.recurringDayOfMonth || (params.recurringDayOfMonth !== 'last' && (params.recurringDayOfMonth < 1 || params.recurringDayOfMonth > 28))) {
                createAlert('invalid recurring day of month');
                return;
            }
            if (params.recurringStartMonth && !/^\d{4}-\d{2}$/.test(params.recurringStartMonth)) {
                createAlert('invalid recurring start month');
                return;
            }
            if (params.recurringEndMonth && !/^\d{4}-\d{2}$/.test(params.recurringEndMonth)) {
                createAlert('invalid recurring end month');
                return;
            }
            params.recurringStartMonth = params.recurringStartMonth || dateFormat('yyyy-mm');
        }

        var results = await ajax({ endPoint: '/addNewPurchase', data: { ...params, month } });
        if (results.result) {
            if (params.id) {
                var index = months[month].purchases.findIndex((purchase) => purchase.id == params.id); // eslint-disable-line eqeqeq
                months[month].purchases[index] = makePurchase({
                    ...params,
                    card: parseInt(params.card),
                    amount: parseFloat(params.amount),
                    transactionDate: parseDate(params.date),
                });
                createNotify('Purchase edited');
            } else {
                months[month].purchases.push(makePurchase({
                    ...params,
                    id: results.purchaseId,
                    card: parseInt(params.card),
                    amount: parseFloat(params.amount),
                    transactionDate: parseDate(params.date),
                }));
                createNotify('Purchase added');
            }
            months[month].purchases.sort((a, b) => a.transactionDate - b.transactionDate);
            setAddNewDialogOpen(false);
            setEditPurchaseParams({});
            setSpendingSlice({ months });
            if (params.addAnother) {
                setTimeout(() => setAddNewDialogOpen(true), 50);
            }
        } else {
            createAlert(results.desc);
            console.log(results);
        }
    };

    var getCurrentMonthDate = () => {
        var { homePath2: month } = urlParams;
        return parseDate(`${month}-01 00:00:00`, false);
    }

    var getEndDateAllowed = () => {
        var endDate = new Date();
        endDate.setDate(32); // sets to beginning-ish of next month
        endDate.setDate(32); // sets to beginning-ish of month after that
        endDate.setDate(0); // sets to end of next month
        endDate.setHours(23, 59, 59, 999); // set hours to end of that day
        return endDate;
    }

    var changeMonth = (direction) => {
        var monthDate = getCurrentMonthDate();
        monthDate.setDate(1);
        monthDate.setMonth(monthDate.getMonth() + direction);

        var endDate = getEndDateAllowed();

        if (monthDate > endDate) {
            return;
        } else if (monthDate < parseDate('2020-06-01')) {
            return;
        }

        navigate(`/p/${dateFormat(monthDate, 'yyyy-mm')}`);
    }

    var editPurchase = (object) => {
        setAddNewDialogOpen(true);
        setEditPurchaseParams(object);
        setEditCreditCardDialogOpen(false);
    }

    var deletePurchase = async (object) => {
        var { homePath2: month } = urlParams;
        var { months } = spendingSlice;

        if (await confirmProm('Are you sure you want to delete this purchase?')) {
            var results = await ajax({ endPoint: '/deletePurchase', data: { id: object.id } });
            if (results.result) {
                var index = months[month].purchases.findIndex((purchase) => purchase.id == object.id); // eslint-disable-line eqeqeq
                months[month].purchases.splice(index, 1);
                setSpendingSlice({ months });
                createNotify('Purchase deleted');
            } else {
                createAlert(results.desc);
                console.log(results);
            }
        }
    }

    var editCreditCard = (object) => {
        setEditCreditCardDialogOpen(true);
        setEditCreditCardParams(object);
        setAddNewDialogOpen(false);
    }

    var submitCreditCardEdit = async (params) => {
        var { homePath2: month } = urlParams;
        var { months } = spendingSlice;

        if (!params.startDate) {
            createAlert('Missing params - start date');
            return;
        }
        if (!params.endDate) {
            createAlert('Missing params - end date');
            return;
        }
        if (params.venmo.amount && params.venmo.amount < 0) {
            createAlert('Venmo cannot be negative');
            return;
        }

        var { cardId, startDate, endDate, venmo } = params;
        var results = await ajax({ endPoint: '/editCreditCard', data: { cardId, startDate, endDate, venmo, month } });
        if (results.result) {
            months[month].cardInfo[params.cardId] = {
                ...params,
                startDate: parseDate(params.startDate),
                endDate: parseDate(params.endDate),
                get noContextObject() { return this; },
            };
            setEditCreditCardDialogOpen(false);
            setEditCreditCardParams({});
            setSpendingSlice({ months });

            createNotify('Credit card successfully edited');
        } else {
            createAlert(results.desc);
            console.log(results);
        }
    };

    var startPurchasesDragResize = usePurchasesDragResize();
    var formatCategoryTotalsRows = useCategoryTotalsRows();

    var { months = {}, siteInfo } = spendingSlice;
    var { creditCards, category1, category2, rewardsPercents } = siteInfo || {};

    var monthInfo = months[month];

    if (!siteInfo || !monthInfo) {
        return <Loading />;
    }

    var monthText = dateFormat(getCurrentMonthDate(), 'mmmm yyyy');

    var purchases = monthInfo.purchases;

    var smallLayout = appLayout.isMobile || ['XS', 'S'].includes(appLayout.layoutSize);

    var category1TotalsRows = formatCategoryTotalsRows('category1');
    var category2TotalsRows = formatCategoryTotalsRows('category2');

    var creditCardTotals = (
        <React.Fragment>
            <CreditCardsTable monthInfo={monthInfo} siteInfo={siteInfo} editCreditCard={(object) => editCreditCard(object)} />
            {/* <Table
                className='monthInfoContainer'
                headers={['Month Info']}
                rows={monthInfoRows}
            /> */}
        </React.Fragment>
    );

    var categoryTables = (
        <div className='cardMonthContainer'>
            <Table
                className='category1TotalsContainer'
                headers={['Category 1', 'Total', 'Count']}
                rows={category1TotalsRows}
            />
            <Table
                className='category2TotalsContainer'
                headers={['Category 2', 'Total', 'Count']}
                rows={category2TotalsRows}
            />
        </div>
    );

    var startDate = new Date(Math.min(...Object.values(monthInfo.cardInfo).map((c) => c.startDate)));
    var endDate = new Date(Math.max(...Object.values(monthInfo.cardInfo).map((c) => c.endDate)));
    var today = (endDate < new Date()) ? endDate : new Date();
    var numberOfDays = round((endDate - startDate) / (1000 * 60 * 60 * 24));
    var currentDay = Math.max(0, Math.floor((today - startDate) / (1000 * 60 * 60 * 24)));
    var progress = round(currentDay / numberOfDays * 100) + '%';

    return (
        <React.Fragment>
            {addNewDialogOpen && (
                <AddNewPurchaseDialog
                    monthText={monthText}
                    close={() => { setAddNewDialogOpen(false); setEditPurchaseParams({}); }}
                    submit={(params) => createNewEntry(params)}
                    editParams={editPurchaseParams}
                    creditCards={Object.values(creditCards)}
                    categories1={Object.values(category1)}
                    categories2={Object.values(category2)}
                    rewardsPercents={Object.values(rewardsPercents)}
                />
            )}
            {editCreditCardDialogOpen && (
                <EditCreditCardDialog
                    close={() => { setEditCreditCardDialogOpen(false); setEditPurchaseParams({}); }}
                    submit={(params) => submitCreditCardEdit(params)}
                    editParams={editCreditCardParams}
                />
            )}
            <div className='spendingTables'>
                <div className='spendingToolBar noSelect'>
                    <div>
                        <div
                            className='newEntryButton'
                            onClick={() => setAddNewDialogOpen(true)}
                        >+</div>
                    </div>
                    <div className='currentMonthBar'>
                        <span onClick={() => changeMonth(-1)}>&#8592;</span>
                        <span style={{ display: 'inline-block', width: 130 }}>{monthText}</span>
                        <span onClick={() => changeMonth(1)}>&#8594;</span>
                    </div>
                    <div className='monthInfoDisplay'>
                        <div>{dateFormat(startDate, 'mmm dd, yyyy')} &#8594;</div>
                        <div style={{ textAlign: 'center' }}>Today: {dateFormat(today, 'mmm dd')}</div>
                        {smallLayout && <div>&#8594; {dateFormat(endDate, 'mmm dd, yyyy')}</div>}
                        <div className='monthProgressBarContainer'>
                            <div className='monthProgressBar' style={{ width: progress }}></div>
                            <div className='monthProgressText'>{currentDay} of {numberOfDays} days ({progress})</div>
                        </div>
                        {!smallLayout && <div>&#8594; {dateFormat(endDate, 'mmm dd, yyyy')}</div>}
                    </div>
                </div>

                {/* <div className='myTableSectionHeader'>Purchases</div> */}
                <div className={classNames('myTableContainer homeBoxShadow purchasesTableContainer', { smallLayout })}>
                    <PurchasesTable
                        editPurchase={(object) => editPurchase(object)}
                        deletePurchase={(object) => deletePurchase(object)}
                        purchases={purchases}
                    />
                    <div className='purchaseTableDragHandle' onMouseDown={startPurchasesDragResize}></div>
                    <div>
                        {!smallLayout && creditCardTotals}
                        {!smallLayout && categoryTables}
                    </div>
                </div>

                {smallLayout && (
                    <React.Fragment>
                        <div className='myTableSectionHeader'>Credit Cards</div>
                        <div className='myTableContainer homeBoxShadow creditCardsTableContainer'>
                            {creditCardTotals}
                        </div>
                    </React.Fragment>
                )}

                {smallLayout && (
                    <React.Fragment>
                        <div className='myTableSectionHeader'>Categories</div>
                        <div className='myTableContainer homeBoxShadow'>
                            {categoryTables}
                        </div>
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
}

function usePurchasesDragResize() {
    var purchasesDragHandle = null;
    var startPurchasesDragResize = (event) => {
        purchasesDragHandle = event.target;
        document.body.classList.add('noSelect');
        window.addEventListener('mousemove', purchasesDragMove);
        window.addEventListener('mouseup', purchasesDragUp);
    };

    var purchasesDragMove = (event) => {
        var parent = purchasesDragHandle.parentElement;
        var parentWidth = parent.clientWidth - 30; // padding on both sides of client
        var left = Math.min(
            Math.max(
                event.clientX - 30, // 30px, 15 for homeContainer left padding, 15 for tableContainer left padding
                200, // min width 200px
            ),
            parentWidth - 200, // max width up to 200px smaller than parent
        );

        // turn the numbers into percents so that it's not fixed on page resize
        left = round(left / parentWidth * 100, 4);
        var right = 100 - left;

        parent.style.gridTemplateColumns = `${left}% 5px calc(${right}% - 5px)`;
    };

    var purchasesDragUp = (event) => {
        document.body.classList.remove('noSelect');
        window.removeEventListener('mousemove', purchasesDragMove);
        window.removeEventListener('mouseup', purchasesDragUp);
    };

    return startPurchasesDragResize;
}

function useCategoryTotalsRows() {
    var { homePath2: month } = useParams();
    var [spendingSlice] = useStateSlice('spending');
    var { months, siteInfo } = spendingSlice;

    var formatCategoryTotalsRows = (type = 'category1') => {
        var categories = siteInfo[type];

        var monthInfo = months[month];
        var purchases = monthInfo.purchases;

        var categorySums = Object.values(categories).reduce((acc, category) => ({ ...acc, [category.value]: { ...category, total: 0, purchaseCount: 0 } }), {});
        for (var purchase of purchases) {
            var category = categorySums[purchase[type]];
            category.total = round(category.total + purchase.totalAmount, 2);
            category.purchaseCount++;
        }
        var categoryTotalsRows = Object.values(categorySums).map((category) => [
            category.label,
            { value: category.total, format: 'money' },
            { value: category.purchaseCount, style: { textAlign: 'center' } },
        ]);

        return categoryTotalsRows;
    };

    return formatCategoryTotalsRows;
}

export default SpendingTables;
